/* src/components/NavBar.css */
.navbar {
    background: #ffffff;    
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000; 
    
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;   
    z-index: 100;
    width: 100%;
  }
  
  .navbar-logo {
    
    justify-self: start;
    cursor: pointer;    
    height: 45px;
    display: block;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
  }
  
 
  
  .nav-links {
    
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 3vh 2rem;
    font-family: 'Inter';
    color:#483838;
    cursor: pointer;
    font-size: 1.3rem;
    
  }
  
  .menu-icon {
    display: none;
  }

  .nav-links:hover {
    color: #D9B38D; /* Change this to your desired hover color */
  }

  .active{
    font-family: 'Inter';
    color:#D9B38D;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;   
    cursor: pointer;
    padding: 3vh 2rem;
    font-size: 1.3rem;
  }

  
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
   
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 40px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      background: #ffffff;
    }
  
    .nav-menu.active {
      background: #ffffff;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 100;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      
      cursor: pointer;
      color: #000000;
    }
    .navbar-logo{
        height: 40px;
    }
    
  }
  